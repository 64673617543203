.responsive-headline {
  animation: text !important;
}

@keyframes text {
  0% {
    color: skyblue;
    margin-bottom: -40px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
}
